import {
	_async, model, Model, idProp, prop, modelFlow,
	_await,
} from 'mobx-keystone';

import { adobeDatastreamSDK, publicSitePath } from '~/global/global.constants';
import { modelNamespace } from '~/util/modelNamespace';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { PersonalizationDecisionsModel } from '~/personalization/Models/PersonalizationDecisions.model';
import { IPersonalizationEvent, PERSONALIZATION_SCOPE, type IPropositions } from '~/personalization/Personalization.types';

export interface IAdobeKNDCRCookies {
	key: string,
	value: string,
}
@model(`${modelNamespace.PERSONALIZATION}/Decision`)
export class PersonalizationStore extends Model({
	id: idProp,
	decisionsModel: prop<PersonalizationDecisionsModel | undefined>().withSetter(),
}) {
	@modelFlow
	sendDisplayEvent = _async(function* (this: PersonalizationStore, decision: PersonalizationDecisionModel) {
		try {
			if (typeof window !== 'undefined') {
				const tsWindow: any = window;
				const {
					alloy,
				} = tsWindow;
				yield alloy('sendEvent', {
					xdm: {
						eventType: 'decisioning.propositionDisplay',
						_experience: {
							decisioning: {
								propositions: [{
									id: decision.decisionId,
									scope: decision.scope,
									scopeDetails: decision.scopeDetails,
								}],
								propositionEventType: {
									display: 1,
								},
							},
						},
					},
					data: {
						__adobe: {
							target: {
								correlationId: decision.correlationId,
								targetEventType: 'impression',
							},
						},
					},
				});
			}
		} catch (error: any) {
			console.log(error);
			// throw new Error(error);
		}
	});

	@modelFlow
	sendInteractEvent = _async(function* (this: PersonalizationStore, decision: PersonalizationDecisionModel) {
		try {
			if (typeof window !== 'undefined') {
				const tsWindow: any = window;
				const {
					alloy,
				} = tsWindow;
				yield alloy('sendEvent', {
					xdm: {
						eventType: 'decisioning.propositionInteract',
						_experience: {
							decisioning: {
								propositions: [{
									id: decision.decisionId,
									scope: decision.scope,
									scopeDetails: decision.scopeDetails,
								}],
								propositionEventType: {
									interact: 1,
								},
							},
						},
					},
					data: {
						__adobe: {
							target: {
								correlationId: decision.correlationId,
								targetEventType: 'interact',
							},
						},
					},
				});
			}
		} catch (error: any) {
			console.log(error);
			// throw new Error(error);
		}
	});

	@modelFlow
	fetchPersonalizationDecisionServerSide = _async(function* (this: PersonalizationStore, {
		cookies, customerId, scope,
	}: { cookies: any, customerId?: number, scope: PERSONALIZATION_SCOPE[] }) {
		const FPIDCookie = cookies?.FPID;

		try {
			if (!scope || scope.length === 0) {
				console.error('Scope is required to fetch personalization decisions');
			}

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const raw = JSON.stringify({
				'event': {
					'xdm': {
						'eventType': 'decisioning.propositionFetch',
						'web': {
							'webPageDetails': {
								'URL': publicSitePath,
							},
							'webReferrer': {
								'URL': '',
							},
						},
						'identityMap': {
							...(FPIDCookie && {
								'FPID': [
									{
										'id': FPIDCookie,
										'authenticatedState': 'ambiguous',
									},
								],
							}),
							...(customerId && {
								'webId': [
									{
										'id': customerId.toString(),
										'authenticatedState': 'authenticated',
										'primary': true,
									},
								],
							}),
						},
					},
				},
				'query': {
					'identity': {
						'fetch': [
							'ECID',
						],
					},
					'personalization': {
						'schemas': [
							'https://ns.adobe.com/personalization/default-content-item',
							'https://ns.adobe.com/personalization/html-content-item',
							'https://ns.adobe.com/personalization/json-content-item',
							'https://ns.adobe.com/personalization/redirect-item',
							'https://ns.adobe.com/personalization/dom-action',
						],
						'decisionScopes': scope,
					},
				},
				'meta': {
					'state': {
						'domain': publicSitePath,
						'cookiesEnabled': true,
						'entries': this.KNDCTRCookiesArray(cookies),
					},
				},
			});

			const requestOptions = {
				method: 'POST',
				headers,
				body: raw,
			};

			const response = yield* _await(fetch(`https://edge.adobedc.net/ee/v2/interact?dataStreamId=${adobeDatastreamSDK}`, requestOptions));

			const data = yield* (_await(response.json()) as any);

			if (response.ok) {
				const personalizationDecisions = data.handle.find((handle: any) => handle.type === 'personalization:decisions');
				return { propositions: [...personalizationDecisions.payload] } as IPersonalizationEvent;
			}
		} catch (error: any) {
			console.error('Error fetching data server side in personalization store', error);
		}
		return {
			propositions: [],
		};
	});

	@modelFlow
	postDisplayEvent = _async(function* (this: PersonalizationStore, {
		cookies, decision,
	}: { cookies: any, decision: PersonalizationDecisionModel }) {
		try {
			if (!decision) {
				console.error('A personalization decision is required to send the display event');
			}

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const raw = JSON.stringify({
				'event': {
					'xdm': {
						'eventType': 'decisioning.propositionDisplay',
						'_experience': {
							'decisioning': {
								'propositions': [
									{
										'id': decision.decisionId,
										'scope': decision.scope,
										'scopeDetails': decision.scopeDetails,
									},
								],
							},
						},
					},
				},
				'query': {
					'identity': {
						'fetch': [
							'ECID',
						],
					},
				},
				'meta': {
					'state': {
						'entries': this.KNDCTRCookiesArray(cookies),
					},
				},
			});

			const requestOptions = {
				method: 'POST',
				headers,
				body: raw,
			};

			yield* _await(fetch(`https://edge.adobedc.net/ee/v2/interact?dataStreamId=${adobeDatastreamSDK}`, requestOptions));
		} catch (error: any) {
			console.error('Error sending server side dispaly event', error);
		}
	});

	getPersonalizationDecision(scope: PERSONALIZATION_SCOPE): IPropositions | undefined {
		if (!this.decisionsModel || !this.decisionsModel?.decisions?.propositions?.length) {
			return undefined;
		}

		const [decision] = this.decisionsModel.groupDecisionsByScope[scope] ?? [];

		return decision;
	}

	KNDCTRCookiesArray(cookies: any): IAdobeKNDCRCookies[] {
		const kndctrCookies = Object.keys(cookies).filter(key => key.startsWith('kndctr'));

		return kndctrCookies.map(key => ({
			key,
			value: cookies[key],
		}));
	}
}
