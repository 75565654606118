import getConfig from 'next/config';

export const isOnServer = typeof window === 'undefined';

const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getConfig() || {};

let globals = {};

try {
	if (!isOnServer && !window.rnbGlobals && Object.keys(publicRuntimeConfig).length > 0) {
		window.rnbGlobals = Object.freeze({
			...publicRuntimeConfig,
			apiUrl: publicRuntimeConfig.clientApiUrl,
			adobeLaunchGlobalUrlSDK: publicRuntimeConfig.adobeLaunch?.globalUrlSDK || '',
			adobeDatastreamSDK: publicRuntimeConfig.adobeDatastreamSDK || '',
			smartyEmbeddedKey: publicRuntimeConfig.smarty?.embeddedKey,
			isCypress: (!isOnServer && window.Cypress) || false,
		});
	} else if (isOnServer) {
		console.info('comparing publicRuntimeConfig', publicRuntimeConfig.isEngage, ' vs ', process.env.isEngage === 'true');
	}

	globals = {
		adobeDatastreamSDK: isOnServer ? serverRuntimeConfig.adobeDatastreamSDK : window.rnbGlobals?.adobeDatastreamSDK,
		adobeLaunchGlobalUrlSDK: isOnServer ? process.env.adobeLaunch_globalUrlSDK : window.rnbGlobals?.adobeLaunchGlobalUrlSDK,
		adobeMediaTrackingEnabled: isOnServer ? process.env.adobeMediaTrackingEnabled : window.rnbGlobals?.adobeMediaTrackingEnabled,
		affirmPublicApiKey: isOnServer ? process.env.affirmPublicApiKey : window.rnbGlobals?.affirmPublicApiKey,
		affirmScriptUrl: isOnServer ? process.env.affirmScriptUrl : window.rnbGlobals?.affirmScriptUrl,
		apiUrl: isOnServer ? `${process.env.ssrApiUrl || serverRuntimeConfig.ssrApiUrl}` : window.rnbGlobals?.apiUrl,
		curalateApiUrl: (isOnServer ? process.env.curalateApiUrl : window.rnbGlobals?.curalateApiUrl) || 'https://api-2.curalate.com',
		curalateProductDataSourceId: (isOnServer ? process.env.curalateProductDataSourceId : window.rnbGlobals?.curalateProductDataSourceId) || 'FtDVHoUuNknInIRW',
		curalateProductFanreelId: (isOnServer ? process.env.curalateProductFanreelId : window.rnbGlobals?.curalateProductFanreelId) || 'dQEwHGEE',
		curalateStoresDataSourceId: (isOnServer ? process.env.curalateStoresDataSourceId : window.rnbGlobals?.curalateStoresDataSourceId) || 'VuzWdMnrZCWRjbOb',
		curalateStoresFanreelId: (isOnServer ? process.env.curalateStoresFanreelId : window.rnbGlobals?.curalateStoresFanreelId) || '5GexKrr8',
		DateMaster: {},
		devBeamEnabled: isOnServer ? process.env.devBeamEnabled === 'true' : window.rnbGlobals?.devBeamEnabled,
		imagePath: (isOnServer ? process.env.imagePath : window.rnbGlobals?.imagePath) || '',
		isCypress: (!isOnServer && window.Cypress) || false,
		isEngage: isOnServer ? process.env.isEngage === 'true' : window.rnbGlobals?.isEngage,
		isEngageTraining: isOnServer ? process.env.isEngageTraining === 'true' : window.rnbGlobals?.isEngageTraining,
		isMobile: (isOnServer ? process.env.isMobile === 'true' : window.rnbGlobals?.isMobile) || false,
		isOnServer,
		isProdEnvironment: publicRuntimeConfig.isProdEnvironment,
		isStagingEnvironment: publicRuntimeConfig.isStagingEnvironment,
		isTestEnvironment: publicRuntimeConfig.isTestEnvironment,
		liveChatEnabled: isOnServer ? process.env.liveChatEnabled === 'true' : window.rnbGlobals?.liveChatEnabled,
		nextJsBuildInfoApiEnabled: isOnServer ? process.env.nextJsBuildInfoApiEnabled === 'true' : window.rnbGlobals?.nextJsBuildInfoApiEnabled,
		newRelic: {
			enabled: isOnServer ? process.env.newRelic_enabled === 'true' : window.rnbGlobals?.newRelic_enabled,
			allowOverrides: isOnServer ? process.env.newRelic_allowOverrides === 'true' : window.rnbGlobals?.newRelic_allowOverrides,
			accountId: (isOnServer ? process.env.newRelic_accountId : window.rnbGlobals?.newRelic_accountId),
			licenseKey: (isOnServer ? process.env.newRelic_licenseKey : window.rnbGlobals?.newRelic_licenseKey),
			trustKey: (isOnServer ? process.env.newRelic_trustKey : window.rnbGlobals?.newRelic_trustKey),
			agentId: (isOnServer ? process.env.newRelic_agentId : window.rnbGlobals?.newRelic_agentId),
			applicationId: (isOnServer ? process.env.newRelic_applicationId : window.rnbGlobals?.newRelic_applicationId),
		},
		noRobots: (isOnServer ? process.env.noRobots === 'true' : window.rnbGlobals?.noRobots),
		oneTrustEnabled: isOnServer ? process.env.oneTrustEnabled === 'true' : window.rnbGlobals?.oneTrustEnabled,
		oneTrustSiteId: (isOnServer ? process.env.oneTrustSiteId : window.rnbGlobals?.oneTrustSiteId),
		pdfPath: (isOnServer ? process.env.pdfPath : window.rnbGlobals?.pdfPath) || '',
		publicSitePath: (isOnServer ? process.env.publicSitePath : window.rnbGlobals?.publicSitePath),
		recommendationsApiUrl: (isOnServer ? process.env.recommendationsApiUrl : window.rnbGlobals?.recommendationsApiUrl),
		recommendationsEnabled: (isOnServer ? process.env.recommendationsEnabled === 'true' : window.rnbGlobals?.recommendationsEnabled),
		reflektionPath: (isOnServer ? process.env.reflektionPath : window.rnbGlobals?.reflektionPath) || '',
		releaseDateMessage: (isOnServer ? process.env.releaseDateMessage : window.rnbGlobals?.releaseDateMessage) || '',
		reviewsApiKey: (isOnServer ? process.env.reviewsApiKey : window.rnbGlobals?.reviewsApiKey) || '',
		reviewsMerchantGroupId: (isOnServer ? process.env.reviewsMerchantGroupId : window.rnbGlobals?.reviewsMerchantGroupId) || '',
		reviewsMerchantId: (isOnServer ? process.env.reviewsMerchantId : window.rnbGlobals?.reviewsMerchantId) || '',
		s7ContentPath: (isOnServer ? process.env.s7ContentPath : window.rnbGlobals?.s7ContentPath) || 'https://rnb.scene7.com/is/content/roomandboard',
		s7ImagePath: (isOnServer ? process.env.s7ImagePath : window.rnbGlobals?.s7ImagePath) || 'https://rnb.scene7.com/is/image/roomandboard',
		searchApiUrl: (isOnServer ? process.env.searchApiUrl : window.rnbGlobals?.searchApiUrl) || '',
		smartyEmbeddedKey: (isOnServer ? process.env.smarty_embeddedKey : window.rnbGlobals?.smartyEmbeddedKey) || '',
		sitePath: (isOnServer ? process.env.sitePath : window.rnbGlobals?.sitePath) || '',
		giftCardsBuyUrl: (isOnServer ? process.env.giftCardsBuyUrl : window.rnbGlobals?.giftCardsBuyUrl),
		giftCardsBalanceUrl: (isOnServer ? process.env.giftCardsBalanceUrl : window.rnbGlobals?.giftCardsBalanceUrl),
		humanSensorEnabled: isOnServer ? process.env.humanSensorEnabled === 'true' : window.rnbGlobals?.humanSensorEnabled,
		humanSensorScriptUrl: (isOnServer ? process.env.humanSensorScriptUrl : window.rnbGlobals?.humanSensorScriptUrl),
		_links: {
			customersToolbar: {
				href: '/api/web/employee/customers-toolbar',
			},
			searchAccessToken: {
				href: '/api/search/token',
			},
			recommendationsAccessToken: {
				href: '/api/recommendations/token',
			},
			productSearch: {
				href: '/api/web/products/search',
			},
			orderStatusPage: {
				href: 'order-status',
			},
			refreshVimeoAccessToken: {
				href: '/api/vimeo/refresh-access-token',
			},
			featureToggles: {
				href: '/api/web/feature-toggles',
			},
			shopWithNewGuest: {
				href: '/api/proxy-customer',
			},
			setTransportationZone: {
				href: '/api/web/shop/transportationzone/current',
			},
		},
	};

	// Figure out how to move this to global dynamic
	if (!isOnServer) {
		const firstDir = window.location.pathname.split('/')[1].toString();

		globals.isMobile = firstDir === 'm';
		globals.apiUrl = (window.rnbGlobals?.apiUrl || window.rnbGlobals?.clientApiUrl) || '/';
	} else if (typeof globals.apiUrl === 'undefined') {
		globals.apiUrl = '/';
	}
} catch (error) {
	console.error('Error in global.constants.js', error);
}

const proxyGlobals = new Proxy(globals, {
	get: (target, prop) => {
		if (prop in target) {
			if (isOnServer) {
				// This is for testing issues on the server with exported constants
				if (prop in publicRuntimeConfig && prop in process.env && publicRuntimeConfig[prop].toString() !== process.env[prop].toString()) {
					console.info('On server', prop, publicRuntimeConfig[prop], ' vs ', process.env[prop]);
				}
				if (prop in publicRuntimeConfig) {
					return publicRuntimeConfig[prop];
				}
				return target[prop];
			}

			if (typeof target[prop] === 'object') {
				return target[prop];
			}

			// This is for testing issues with the exported constants
			if (!window.rnbGlobals || typeof window.rnbGlobals !== 'object' || !(prop in window.rnbGlobals)) {
				return target[prop];
			}
			if (target[prop] !== window.rnbGlobals[prop]) {
				console.info('On client', prop, target[prop], ' vs ', window.rnbGlobals[prop]);
			}
			return window.rnbGlobals[prop];
		}
		return undefined;
	},
});

if (!isOnServer) {
	window.test = proxyGlobals;
}

export const {
	affirmPublicApiKey,
	affirmScriptUrl,
	adobeDatastreamSDK,
	adobeLaunchGlobalUrlSDK,
	adobeMediaTrackingEnabled,
	apiUrl,
	curalateApiUrl,
	curalateProductDataSourceId,
	curalateProductFanreelId,
	curalateStoresDataSourceId,
	curalateStoresFanreelId,
	DateMaster,
	devBeamEnabled,
	giftCardsBalanceUrl,
	giftCardsBuyUrl,
	imagePath,
	isCypress,
	isEngage,
	isEngageTraining,
	isMobile,
	isProdEnvironment,
	isStagingEnvironment,
	isTestEnvironment,
	liveChatEnabled,
	nextJsBuildInfoApiEnabled,
	newRelic,
	noRobots,
	oneTrustEnabled,
	oneTrustSiteId,
	pdfPath,
	publicSitePath,
	recommendationsApiUrl,
	recommendationsEnabled,
	reflektionPath,
	releaseDateMessage,
	reviewsApiKey,
	reviewsMerchantGroupId,
	reviewsMerchantId,
	searchApiUrl,
	smartyEmbeddedKey,
	s7ContentPath,
	s7ImagePath,
	sitePath,
	humanSensorEnabled,
	humanSensorScriptUrl,
	_links,
} = proxyGlobals;
