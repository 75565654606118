import Cookie from 'js-cookie';
import { computed, makeObservable, observable } from 'mobx';

import type { ISiteMarketingMessagingData } from '~/site-marketing-messaging/Types/SiteMarketingMessaging.types';
import { type SiteMarketingMessage } from '~/site-marketing-messaging/Models/SiteMarketingMessage.model';
import { DISPLAY_ON, SITE_MARKETING_MESSAGE_NAME_COOKIE } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';

export class SiteMarketingMessaging {
	_activeMessageIndex = -1;

	_paused = false;

	_siteMarketingMessages: SiteMarketingMessage[] = [];

	defaultMessageName?: string = '';

	isOnCartPage = false;

	isOnCareersPage = false;

	constructor({
		activeMessageIndex,
		defaultMessageName,
		isOnCareersPage,
		isOnCartPage,
		paused,
		siteMarketingMessages = [],
	}: ISiteMarketingMessagingData) {
		makeObservable(this, {
			_activeMessageIndex: observable,
			_paused: observable,
			_siteMarketingMessages: observable,
			activeMessage: computed,
			activeMessageName: computed,
			activeMessageIndex: computed,
			activeMessageTemplate: computed,
			hasSiteMarketingMessages: computed,
			hasSiteMarketingMessagesCareer: computed,
			hasSiteMarketingMessagesCart: computed,
			paused: computed,
			showControls: computed,
			siteMarketingMessages: computed,
			siteMarketingMessagesCareer: computed,
			siteMarketingMessagesCart: computed,
			siteMarketingMessagesCount: computed,
			siteMarketingMessagesSitewide: computed,
		});

		this._activeMessageIndex = activeMessageIndex;
		this._paused = paused;
		this._siteMarketingMessages = siteMarketingMessages;
		this.defaultMessageName = defaultMessageName;
		this.isOnCareersPage = isOnCareersPage;
		this.isOnCartPage = isOnCartPage;
	}

	static create(siteMarketingMessagingData: ISiteMarketingMessagingData) {
		const siteMarketingMessagingModel = new SiteMarketingMessaging(siteMarketingMessagingData);

		const { defaultMessageIndex } = siteMarketingMessagingModel;

		siteMarketingMessagingModel._activeMessageIndex = defaultMessageIndex;

		return siteMarketingMessagingModel;
	}

	get activeMessage() {
		return this.siteMarketingMessages[this.activeMessageIndex];
	}

	get activeMessageIndex() {
		return this._activeMessageIndex;
	}

	set activeMessageIndex(activeMessageIndex) {
		this._activeMessageIndex = activeMessageIndex;
	}

	get activeMessageName() {
		const { name = '' } = this.activeMessage;

		return name;
	}

	get activeMessageTemplate() {
		const { template = '' } = this.activeMessage;

		return template;
	}

	get defaultMessage() {
		return this.siteMarketingMessages.find(({ name = '' }) => name === this.defaultMessageName);
	}

	get defaultMessageIndex() {
		if (this.defaultMessage) {
			return this.siteMarketingMessages.indexOf(this.defaultMessage);
		}

		return 0;
	}

	get hasSiteMarketingMessages() {
		return Boolean(this.siteMarketingMessages.length);
	}

	get hasSiteMarketingMessagesCareer() {
		return Boolean(this.siteMarketingMessagesCareer.length);
	}

	get hasSiteMarketingMessagesCart() {
		return Boolean(this.siteMarketingMessagesCart.length);
	}

	get paused() {
		return this._paused;
	}

	set paused(paused) {
		if (paused) {
			Cookie.set(SITE_MARKETING_MESSAGE_NAME_COOKIE, this.activeMessageName);
		} else {
			Cookie.remove(SITE_MARKETING_MESSAGE_NAME_COOKIE);
		}

		this._paused = paused;
	}

	get showControls() {
		return this.siteMarketingMessages.length > 1;
	}

	get siteMarketingMessages() {
		if (this.isOnCartPage && this.hasSiteMarketingMessagesCart) {
			return this.siteMarketingMessagesCart;
		}

		if (this.isOnCareersPage) {
			return this.hasSiteMarketingMessagesCareer ? this.siteMarketingMessagesCareer : [];
		}

		return this.siteMarketingMessagesSitewide;
	}

	set siteMarketingMessages(siteMarketingMessages) {
		this._siteMarketingMessages = siteMarketingMessages;
	}

	get siteMarketingMessagesCareer() {
		return this._siteMarketingMessages.filter(({ displayOn }) => displayOn === DISPLAY_ON.CAREERS);
	}

	get siteMarketingMessagesCart() {
		return this._siteMarketingMessages.filter(({ displayOn }) => displayOn === DISPLAY_ON.CART);
	}

	get siteMarketingMessagesCount() {
		return this.siteMarketingMessages.length;
	}

	get siteMarketingMessagesSitewide() {
		return this._siteMarketingMessages.filter(({ displayOn }) => displayOn === DISPLAY_ON.SITEWIDE);
	}
}
