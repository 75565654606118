import {
	computed, observable, makeObservable, action,
} from 'mobx';

import { isNumber } from '~/util/isNumber';
import { isEngage } from '~/global/global.constants';

export class MagicModalModel {
	absoluteScrollTop = null;

	alignToTopOfWindow = false;

	accessibleTitle = '';

	anchorBottom = false;

	closeButtonHandler = null;

	closeBtnWhiteBackground = false;

	closeButtonText = 'Close';

	closeModalOnOverlayClick = !isEngage;

	closeModalOnEscKey = !isEngage;

	containerClass = '';

	content = undefined;

	dropShadow = false;

	flushSides = false;

	fullBleed = false;

	height = 'auto';

	id = '';

	initialScrollTop = 0;

	isLoading = false;

	isOpen = false;

	isTray = false;

	keepScrollPosition = false;

	maxWidth = 900;

	onBeforeCloseModal = null;

	onOverlayClick = null;

	onCloseModal = null;

	onCloseFocusElement = null;

	onOpenModal = null;

	overflow = 'visible';

	reverseCloseButton = false;

	scrollTop = 0;

	showCloseButton = true;

	showOverlay = true;

	title = 'Room & Board';

	trLinkEventCompName = null;

	width = '90vw';

	WrapperComponent = null;

	defaults = {
		absoluteScrollTop: null,
		alignToTopOfWindow: false,
		accessibleTitle: '',
		anchorBottom: false,
		closeBtnWhiteBackground: false,
		closeButtonHandler: null,
		closeButtonText: 'Close',
		closeModalOnOverlayClick: !isEngage,
		closeModalOnEscKey: !isEngage,
		containerClass: '',
		dataQa: null,
		dropShadow: false,
		flushSides: false,
		fullBleed: false,
		height: 'auto',
		id: '',
		initialScrollTop: 0,
		isLoading: false,
		isOpen: false,
		isTray: false,
		keepScrollPosition: false,
		maxWidth: 900,
		onBeforeCloseModal: null,
		onOverlayClick: null,
		onCloseFocusElement: null,
		onCloseModal: null,
		onOpenModal: null,
		overflow: 'visible',
		reverseCloseButton: false,
		scrollTop: 0,
		showCloseButton: true,
		showHeader: true,
		showOverlay: true,
		title: 'Room & Board',
		trLinkEventCompName: null,
		width: '90vw',
		WrapperComponent: null,
	};

	constructor() {
		makeObservable(this, {
			absoluteScrollTop: observable,
			alignToTopOfWindow: observable,
			accessibleTitle: observable,
			anchorBottom: observable,
			closeButtonHandler: observable,
			closeButtonText: observable,
			closeModalOnOverlayClick: observable,
			closeModalOnEscKey: observable,
			containerClass: observable,
			dropShadow: observable,
			flushSides: observable,
			fullBleed: observable,
			height: observable,
			id: observable,
			initialScrollTop: observable,
			isLoading: observable,
			isOpen: observable,
			isTray: observable,
			keepScrollPosition: observable,
			onBeforeCloseModal: observable,
			onOverlayClick: observable,
			onCloseModal: observable,
			onCloseFocusElement: observable,
			onOpenModal: observable,
			reverseCloseButton: observable,
			scrollTop: observable,
			setIsOpen: action.bound,
			showCloseButton: observable,
			showOverlay: observable,
			title: observable,
			trLinkEventCompName: observable,
			width: observable,
			maxWidth: observable,
			WrapperComponent: observable.ref,
			content: observable.ref,
			heightOutput: computed,
			marginTop: computed,
			widthOutput: computed,
		});

		Object.assign(this, this.defaults);
	}

	get heightOutput() {
		if (isNumber(this.height)) {
			return `${this.height}px`;
		}
		return this.height;
	}

	get marginTop() {
		const scrollTop = this.scrollTop || 0;

		// deal with integers and append 'px', or take a string for custom 'vh' or 'calc' values
		if (isNumber(this.absoluteScrollTop)) {
			return `${this.absoluteScrollTop}px`;
		}
		if (this.absoluteScrollTop !== null) {
			return this.absoluteScrollTop;
		}
		if (isEngage) {
			return `${(scrollTop + 100).toString()}px`;
		}
		return `calc(15vh + ${scrollTop}px)`;
	}

	get widthOutput() {
		if (isNumber(this.width)) {
			return `${this.width}px`;
		}
		return this.width;
	}

	get hasTitle() {
		return Boolean(this.title);
	}

	setIsOpen(isOpen) {
		this.isOpen = isOpen;
	}
}
