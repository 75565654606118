'use client';

import React, { useCallback, useEffect } from 'react';

import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';
import { useMagicOverlayPageTransition } from '~/hooks/useMagicOverlayPageTransition';
import { START_LOADING_EVENT, STOP_LOADING_EVENT } from '~/hooks/useRNBRouter';

export const PageTransition = () => {
	const { clientSidePageTransition, transitionReady } = useMagicOverlayPageTransition();

	const startLoadingTransition = useCallback(() => {
		console.info('START_LOADING_EVENT', {
			store: clientSidePageTransition.store,
			enabled: clientSidePageTransition.enabled.get(),
			transitionReady,
		});
		if (transitionReady && clientSidePageTransition.enabled.get()) {
			console.info('START_LOADING_EVENT');
			clientSidePageTransition.store.startLoading();
		}
	}, [transitionReady, clientSidePageTransition.enabled.get(), clientSidePageTransition.store]);

	const stopLoadingTransition = useCallback(() => {
		if (transitionReady && clientSidePageTransition.enabled.get()) {
			window.setTimeout(() => {
				clientSidePageTransition.store.stopLoading();
			}, 500);
		}
	}, [transitionReady, clientSidePageTransition.enabled.get(), clientSidePageTransition.store]);

	useEffect(() => {
		window.addEventListener(START_LOADING_EVENT, startLoadingTransition);
		window.addEventListener(STOP_LOADING_EVENT, stopLoadingTransition);

		return function removeLoadingTransitionListeners() {
			window.removeEventListener(START_LOADING_EVENT, startLoadingTransition);
			window.removeEventListener(STOP_LOADING_EVENT, stopLoadingTransition);
		};
	}, [transitionReady]);

	if (!transitionReady || !clientSidePageTransition.enabled.get()) {
		return null;
	}

	return (
		<>
			<MagicOverlay store={clientSidePageTransition.store} />
		</>
	);
};
